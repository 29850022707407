<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
    <div class="dialog-content">
      <p
        class="text-center"
        v-html="
          $t('components/lims/modals/ModalConfirmation.content01', {
            notificationSubject: this.notificationData ? this.notificationData.notification.subject : '',
          })
        "
      ></p>
      <p class="text-center"></p>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button @click="onDelete" :disabled="isProcessing" class="md-danger lims-form-button">
        {{ $t('global/button/button.delete') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import notificationsService from '@/services/notifications.service';
import modalMixins from './modal.mixins';
import { APP_EVENTS, newAppEvent } from '@/core/constants';
import { mapActions } from 'vuex';

export default {
  name: 'ModalDeleteNotification',
  mixins: [modalMixins],
  data() {
    return {
      notificationData: '',
    };
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    open(data) {
      if (data) {
        this.notificationData = data;
        this.isVisible = true;
      }
    },

    onCancel() {
      this.$emit('onCancel');
      this.close();
    },
    async onDelete() {
      this.isProcessing = true;
      const dataDelete = {
        userNotificationIds: [this.notificationData.notification.userNotificationId],
        action: this.$t('entities/case/bulkActionName.Delete'),
      };
      const res = await notificationsService.updateNotification(dataDelete);
      this.isProcessing = false;
      if (res.err) {
        return this.$alertError(res.err + ' error');
      } else {
        this.addEvent(newAppEvent(APP_EVENTS.EVT_NOTIFICATION_LIST_REFRESH, {}));
        this.close();
        return this.$alertSuccess(this.$t('components/lims/modals/ModalDeleteNotification/alert/deleteSuccessfully'));
      }
    },
  },
};
</script>

<style lang="scss"></style>
