<template>
  <div>
    <modal-delete-notification ref="deleteNotificationModal"></modal-delete-notification>
    <modal-bulk-action-delete-notification
      ref="bulkActionDeleteNotificationModal"
      :notificationList="notifications"
    ></modal-bulk-action-delete-notification>
    <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
        <div class="toolbar-row select-action">
          <v-select
            class="action-dropdown"
            v-model="bulkAction"
            :options="bulkActionList"
            :reduce="(item) => item.value"
            :placeholder="$t('pages/case/CaseManagement/actionDropdown/selectAction.placeholder')"
          >
            <template #option="{ label }">{{ $t(label) }}</template>
            <template #selected-option="{ label }">{{ $t(label) }}</template>
          </v-select>
          <md-button
            @click="onApply"
            class="md-button lims-form-button md-theme-default apply-btn"
            :disabled="selectRow.length === 0 || !bulkAction"
            >{{ $t('global/button/button.apply') }}</md-button
          >
        </div>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head class="checkbox-column">
              <md-checkbox v-model="selectAll"></md-checkbox>
            </md-table-head>
            <md-table-head v-for="(col, index) in colTbl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" @click="sortCol(col.key, col.sort)">
                {{ $t(col.name) }}
                <span>
                  <md-icon v-if="col.sort">
                    {{
                      col.key === orderState.orderField
                        ? orderState.orderBy === 'asc'
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                        : hover === index
                        ? 'arrow_drop_up'
                        : ''
                    }}
                  </md-icon>
                </span>
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="item in notifications"
            :key="item.userNotificationId"
            v-long-press="300"
            @click.native="onNavigate(item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
            :class="checkRowHighlight(item)"
          >
            <md-table-cell class="checkbox-column">
              <md-checkbox v-model="selectRow" :value="item.userNotificationId" class="lims-checkbox"></md-checkbox>
            </md-table-cell>
            <md-table-cell class="id-column">{{ item.from }}</md-table-cell>
            <md-table-cell>{{ item.subject }}</md-table-cell>
            <md-table-cell class="notification-body" v-html="item.body"></md-table-cell>
            <md-table-cell>{{ item.timeSent ? formatDateTime(item.timeSent) : '' }}</md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <div style="width: 30px">
                  <md-button class="md-just-icon md-danger md-simple" @click.stop="onDeleteNotification(item)">
                    <md-icon>delete_forever</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import { APP_EVENTS, BULK_ACTION, newAppEvent } from '@/core/constants';
import ModalDeleteNotification from '@/components/Lims/modals/ModalDeleteNotification.vue';
import ModalBulkActionDeleteNotification from '@/components/Lims/modals/ModalBulkActionDeleteNotification.vue';
import notificationsService from '@/services/notifications.service';
import { mapActions } from 'vuex';
import { DateTimeHelper } from '@/core/helpers';

export default {
  name: 'NotificationList',
  mixins: [FormMixins],
  components: {
    ModalDeleteNotification,
    ModalBulkActionDeleteNotification,
  },
  props: {
    sort: {
      type: Object,
      require: true,
    },
    notifications: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  created() {
    this.orderState = this.sort;
  },

  data() {
    return {
      selectRow: [],
      bulkAction: '',
      longPressMode: false,
      plusInterval: null,
      colTbl: [
        {
          name: 'pages/MyAccount/Notifications/List/NotificationList/column.from',
          key: 'from',
          sort: false,
          class: 'from-column',
        },
        {
          name: 'pages/MyAccount/Notifications/List/NotificationList/column.subject',
          key: 'subject',
          sort: true,
          class: 'subject-column',
        },
        {
          name: 'pages/MyAccount/Notifications/List/NotificationList/column.body',
          key: 'body',
          sort: false,
          class: 'body-column',
        },
        {
          name: 'pages/MyAccount/Notifications/List/NotificationList/column.date',
          key: 'timeSent',
          sort: true,
          class: 'date-col',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      bulkActionList: [
        {
          value: BULK_ACTION.MarkAsRead,
          label: 'entities/case/bulkActionName.MarkAsRead',
        },
        {
          value: BULK_ACTION.MarkAsUnRead,
          label: 'entities/case/bulkActionName.MarkAsUnRead',
        },
        {
          value: BULK_ACTION.Delete,
          label: 'entities/case/bulkActionName.Delete',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'userType',
        orderBy: 'asc',
      },
    };
  },
  computed: {
    selectAll: {
      get: function () {
        return this.notifications ? this.notifications.length == this.selectRow.length : false;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          this.notifications.forEach(function (item) {
            selected.push(item.userNotificationId);
          });
        }
        this.selectRow = selected;
      },
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),

    async onNavigate(item) {
      // mask as read
      const data = {
        userNotificationIds: [item?.userNotificationId],
        action: 'Read',
      };
      await notificationsService.updateNotification(data);
      if (item?.link) {
        // eslint-disable-next-line
        await this.$router.push(item.link.replace(/^.*\/\/[^\/]+/, ''));
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },

    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },

    checkRowHighlight(item) {
      if (item.isRead) {
        return 'grey-color';
      } else {
        return '';
      }
    },

    formatDateTime(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },

    async onApply() {
      if (this.bulkAction == BULK_ACTION.Delete) {
        this.$refs.bulkActionDeleteNotificationModal.open({ notificationSelected: this.selectRow });
      }
      if (this.bulkAction == BULK_ACTION.MarkAsRead || this.bulkAction == BULK_ACTION.MarkAsUnRead) {
        let data;
        if (this.bulkAction == BULK_ACTION.MarkAsRead) {
          data = {
            userNotificationIds: this.selectRow,
            action: 'Read',
          };
        }
        if (this.bulkAction == BULK_ACTION.MarkAsUnRead) {
          data = {
            userNotificationIds: this.selectRow,
            action: 'UnRead',
          };
        }
        const res = await notificationsService.updateNotification(data);
        if (res.err) {
          return this.$alertError(res.err + ' error');
        } else {
          this.$alertSuccess(
            this.$t('pages/MyAccount/Notifications/List/NotificationList/bulkAction.success', {
              bulkAction: this.bulkAction,
            }),
          );
          this.clearSelectedData();
          this.addEvent(newAppEvent(APP_EVENTS.EVT_NOTIFICATION_LIST_REFRESH, { bulkAction: this.bulkAction }));
        }
      }
    },

    clearSelectedData() {
      // clear selected checkbox and action
      this.selectRow = [];
      this.bulkAction = '';
    },

    onDeleteNotification(item) {
      this.$refs.deleteNotificationModal.open({ notification: item });
    },

    hoverIn(index) {
      this.hover = index;
    },
    hoverOut() {
      this.hover = '';
    },

    sortCol(colName, isSort) {
      if (isSort) {
        if (this.orderState.orderField === colName) {
          if (this.orderState.orderBy === 'asc') {
            this.orderState.orderBy = 'desc';
          } else {
            this.orderState.orderBy = 'asc';
          }
        } else {
          this.orderState.orderField = colName;
          this.orderState.orderBy = 'asc';
        }
      }
    },
  },
};
</script>

<style lang="scss">
.table-hover tbody tr:hover {
  .notification-body {
    table > tbody > tr {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}
.notification-body {
  .wrapper {
    height: auto;
  }
  table > tbody > tr > td {
    padding: 0 !important;
    width: auto !important;
  }
}
.grey-color {
  .notification-body {
    table > tbody > tr {
      color: #b6b0b0;
      span {
        color: #b6b0b0 !important;
      }
    }
  }
}
</style>
