<template>
  <form ref="form" class="list-filter notification-list-filter" v-if="filters">
    <div class="filter-input">
      <md-field class="input-search">
        <md-icon>search</md-icon>
        <md-input
          v-model="filters.search"
          :placeholder="$t('pages/MyAccount/Notifications/List/NotificationList/placeholderSearch')"
          maxlength="250"
        ></md-input>
      </md-field>
      <div class="filter-action">
        <md-button @click="onClear" class="clear-btn">
          {{ $t('global/button/button.clear') }}
        </md-button>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  mixins: [],
  async created() {
    this.onCreated();
  },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      filters: null,
    };
  },
  methods: {
    onCreated() {
      this.filters = this.value;
    },
    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      Object.keys(this.filters).map((k) => {
        // eslint-disable-next-line security/detect-object-injection
        this.filters[k] = this.defaultValues[k];
      });
    },
  },
};
</script>
<style lang="scss"></style>
