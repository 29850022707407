<template>
  <lims-block :cardText="false" class="list-page">
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="query">
        <notification-list :notifications="items" :sort="query.sort">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <notification-list-filter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                ></notification-list-filter>
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
          </md-table-toolbar>
        </notification-list>
        <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import NotificationList from '@/pages/MyAccount/Notifications/List/NotificationList.vue';
import NotificationListFilter from '@/pages/MyAccount/Notifications/List/NotificationListFilter.vue';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import { notificationQuery } from '@/query';
import { ConfigMixins, ListWithFilterMixins, TabMixins } from '@/core/mixins';
import cloneDeep from 'lodash/cloneDeep';
import notificationsService from '@/services/notifications.service';
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENTS, newAppEvent, NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';

export default {
  mixins: [ConfigMixins, ListWithFilterMixins, TabMixins],
  components: {
    NotificationList,
    NotificationListFilter,
    LimsRowPerPage,
    LimsPagination,
  },
  created() {
    this.initState();
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
    };
  },
  computed: {
    fields() {
      return notificationQuery.filters.fields;
    },
    defaultValues() {
      return notificationQuery.filters.defaultValues;
    },
    ...mapGetters('auth', ['userId']),
    ...mapGetters('app/event', [APP_EVENTS.EVT_NOTIFICATION_LIST_REFRESH]),
  },
  watch: {
    [APP_EVENTS.EVT_NOTIFICATION_LIST_REFRESH]: {
      deep: true,
      handler: async function (val) {
        // reload data
        if (val) {
          this.initState();
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_NOTIFICATION_LIST_REFRESH));
        }
      },
    },
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(notificationQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(notificationQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(notificationQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },
    async search(queryObject) {
      const queryObjNew = cloneDeep(queryObject);
      if (queryObjNew.filters.search.length > 0 && queryObjNew.filters.search.length < NUMBER_OF_CHARACTERS_SEARCH) {
        return;
      }

      const { error, data } = await notificationsService.search({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        sort: queryObject.sort.orderField ? `${queryObject.sort.orderField}:${queryObject.sort.orderBy}` : '',
        userId: this.userId,
      });

      if (error) {
        this.$alertError(error);
        return;
      }

      // binding data
      this.items = data.notifications.items;
      this.totalItem = data.notifications.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        //...buildFilterQueryParams(queryObject.sort),
      };

      if (JSON.stringify(query) != JSON.stringify(this.$route.query)) {
        this.$router
          .push({
            query,
          })
          .catch((error) => {
            if (
              error.name === 'NavigationDuplicated' &&
              error.message.includes('Avoided redundant navigation to current location')
            ) {
              history.pushState({}, '', new URL(new URL(window.location)));
            }
          });
      }
    },
    onResetFilters() {
      this.query.filters = cloneDeep(notificationQuery.filters.defaultValues);
    },
  },
};
</script>

<style></style>
