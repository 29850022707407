<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
    <div class="dialog-content">
      <p class="text-center" v-html="$t('components/lims/modals/ModalBulkActionDeleteNotification.content')"></p>
      <ul>
        <li v-for="(item, index) in notificationSelectedList" :key="index">
          {{ item.subject }} at {{ formatDateTime(item.timeSent) }}
        </li>
      </ul>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button @click="onDelete" :disabled="isProcessing" class="md-danger lims-form-button">
        {{ $t('global/button/button.delete') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import notificationsService from '@/services/notifications.service';
import { DateTimeHelper } from '@/core/helpers';
import { APP_EVENTS, newAppEvent } from '@/core/constants';
import { mapActions } from 'vuex';

export default {
  name: 'ModalDeleteNotification',
  mixins: [modalMixins],
  props: {
    notificationList: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      notificationSelectedIdList: [],
      notificationSelectedList: [],
    };
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),

    formatDateTime(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },

    open(data) {
      if (data) {
        this.notificationSelectedIdList = data.notificationSelected;
        this.notificationSelectedList = this.notificationList.filter((item) =>
          this.notificationSelectedIdList.some((x) => x === item.userNotificationId),
        );
        this.isVisible = true;
      }
    },

    onCancel() {
      this.$emit('onCancel');
      this.close();
    },
    async onDelete() {
      this.isProcessing = true;
      const dataDelete = {
        userNotificationIds: this.notificationSelectedIdList,
        action: this.$t('entities/case/bulkActionName.Delete'),
      };
      const res = await notificationsService.updateNotification(dataDelete);
      this.isProcessing = false;
      if (res.err) {
        return this.$alertError(res.err + ' error');
      } else {
        this.addEvent(newAppEvent(APP_EVENTS.EVT_NOTIFICATION_LIST_REFRESH, {}));
        this.close();
        return this.$alertSuccess(
          this.$t('pages/MyAccount/Notifications/List/NotificationList/bulkAction.success', {
            bulkAction: 'delete',
          }),
        );
      }
    },
  },
};
</script>

<style lang="scss"></style>
